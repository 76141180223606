var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Internos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Operaciones")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Contratos Internos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" ")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"col-6 pt-2 pb-2"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Formulario "+_vm._s(_vm.accion)+" Contratos")])]),_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_vm._m(1),_c('li',{staticClass:"nav-item"},[(_vm.id != null)?_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Tarifarios","data-toggle":"tab","href":"#Tarifarios"}},[_vm._v("Tarifarios")]):_vm._e()])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Principal"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"fecha_inicial"}},[_vm._v("# Contrato")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.no_contrato),expression:"form.no_contrato"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.no_contrato.$invalid
                                ? 'is-invalid'
                                : 'is-valid',attrs:{"type":"text","maxlength":"30","id":"no_contrato","disabled":!_vm.$store.getters.can('cs.contratosInternos.edit')},domProps:{"value":(_vm.form.no_contrato)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "no_contrato", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-5"},[_c('label',{attrs:{"for":"empresas"}},[_vm._v("Empresa")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                              _vm.$v.form.empresa_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              _vm.$store.getters.getDarkMode
                                ? 'dark-vselect'
                                : '',
                            ],attrs:{"placeholder":"Empresas","label":"razon_social","options":_vm.listasForms.empresas,"filterable":false,"disabled":!_vm.$store.getters.can('cs.contratosInternos.edit')},on:{"search":_vm.buscarEmpresas,"input":function($event){return _vm.selectEmpresa()}},model:{value:(_vm.empresa),callback:function ($$v) {_vm.empresa=$$v},expression:"empresa"}})],1),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"bloque"}},[_vm._v("Bloque")]),_c('v-select',{class:[
                                _vm.$v.form.bloque_id.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                _vm.$store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ],attrs:{"placeholder":"Bloque","label":"nombre","options":_vm.listasForms.bloques,"multiple":""},on:{"search":_vm.buscarBloque,"input":function($event){return _vm.selectBloque()}},model:{value:(_vm.form.bloques),callback:function ($$v) {_vm.$set(_vm.form, "bloques", $$v)},expression:"form.bloques"}})],1)]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"fecha_ini"}},[_vm._v("Fecha Inicio")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_ini),expression:"form.fecha_ini"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_ini.$invalid
                                ? 'is-invalid'
                                : 'is-valid',attrs:{"type":"date","id":"fecha_ini","disabled":!_vm.$store.getters.can('cs.contratosInternos.edit')},domProps:{"value":(_vm.form.fecha_ini)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_ini", $event.target.value)},_vm.validaFechas]}})]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"fecha_fin"}},[_vm._v("Fecha Fin")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_fin),expression:"form.fecha_fin"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_fin.$invalid
                                ? 'is-invalid'
                                : 'is-valid',attrs:{"type":"date","id":"fecha_fin","disabled":!_vm.$store.getters.can('cs.contratosInternos.edit')},domProps:{"value":(_vm.form.fecha_fin)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_fin", $event.target.value)},_vm.validaFechas]}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"valor"}},[_vm._v("Valor")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.valor),expression:"form.valor"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.valor.$invalid
                                ? 'is-invalid'
                                : 'is-valid',attrs:{"type":"number","id":"valor","disabled":!_vm.$store.getters.can('cs.contratosInternos.edit')},domProps:{"value":(_vm.form.valor)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "valor", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"funcionarios"}},[_vm._v("Admin Contrato")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                              _vm.$v.form.funcionario_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              _vm.$store.getters.getDarkMode
                                ? 'dark-vselect'
                                : '',
                            ],attrs:{"placeholder":"Admin Contrato","label":"nombres","options":_vm.listasForms.funcionarios,"filterable":false,"disabled":!_vm.$store.getters.can('cs.contratosInternos.edit')},on:{"search":_vm.buscarFuncionarios,"input":function($event){return _vm.selectFuncionarios()}},model:{value:(_vm.funcionario),callback:function ($$v) {_vm.funcionario=$$v},expression:"funcionario"}})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"estado"}},[_vm._v("Estado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.estado),expression:"form.estado"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.estado.$invalid
                                ? 'is-invalid'
                                : 'is-valid',attrs:{"disabled":!_vm.form.id ||
                              _vm.estado == 2 ||
                              !_vm.form.link_documento ||
                              (_vm.$refs.CsContratoInternoTarifario &&
                                _vm.$refs.CsContratoInternoTarifario.tarifarios &&
                                _vm.$refs.CsContratoInternoTarifario.tarifarios
                                  .length == 0)},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.estados),function(estado){return _c('option',{key:estado.numeracion,domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])})],2)]),_c('div',{staticClass:"form-group col-md-5"},[_c('label',{attrs:{"for":"observaciones"}},[_vm._v("Observaciones")]),_c('br'),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.observaciones),expression:"form.observaciones"}],staticClass:"form-control form-control-sm",attrs:{"vtype":"date","id":"fecha_final","rows":"2","disabled":!_vm.$store.getters.can('cs.contratosInternos.edit')},domProps:{"value":(_vm.form.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "observaciones", $event.target.value)}}})]),(_vm.form.id)?_c('div',{staticClass:"form-group col-md-4"},[_vm._m(2),(_vm.form.link_documento == null && _vm.estado == 1)?_c('div',{staticClass:"form-group"},[_c('input',{ref:"link_documento",staticClass:"form-control-file",attrs:{"type":"file","id":"link_documento","accept":".pdf, .PDF","disabled":!_vm.$store.getters.can(
                                  'cs.contratosInternos.edit'
                                )},on:{"change":_vm.obtenerArchivo}}),_c('br'),_c('button',{staticClass:"btn btn-success",attrs:{"id":"guardar_documento","type":"button","data-html":"true","title":"Guardar Documento","data-toggle":"tooltip","disabled":!_vm.$store.getters.can(
                                  'cs.contratosInternos.edit'
                                ) || !_vm.link_documento},on:{"click":function($event){return _vm.saveDocumento()}}},[_c('i',{staticClass:"fa fa-save"})])]):_vm._e(),_c('div',{staticClass:"form-group"},[(_vm.form.link_documento)?_c('button',{staticClass:"btn btn-secondary",attrs:{"id":"BotonDescargar","href":""},on:{"click":function($event){return _vm.descargarDocumento()}}},[_vm._v(" Descargar ")]):_vm._e(),(
                                _vm.$store.getters.can(
                                  'cs.contratosInternos.delete'
                                ) && _vm.form.link_documento
                              )?_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button","disabled":_vm.form.estado == 2 ||
                                !_vm.$store.getters.can(
                                  'cs.contratosInternos.edit'
                                )},on:{"click":_vm.destroyContrato}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e()])]):_vm._e()])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"card card-outline card-navy col-md-12 m-2 p-2"},[_vm._m(3),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nombre),expression:"form.nombre"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.nombre.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.form.nombre)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nombre", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-12"},[_c('label',{attrs:{"for":"identificacion"}},[_vm._v("Teléfono")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.telefono),expression:"form.telefono"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.telefono.$invalid
                                ? 'is-invalid'
                                : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.form.telefono)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "telefono", $event.target.value)}}})]),_c('div',{staticClass:"col-md-12 pt-2 pb-2"},[_c('label',{attrs:{"for":"celular"}},[_vm._v("Correo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.correo),expression:"form.correo"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.correo.$invalid
                                ? 'is-invalid'
                                : 'is-valid',attrs:{"type":"email"},domProps:{"value":(_vm.form.correo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "correo", $event.target.value)}}})])])])])])]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"Tarifarios"}},[_c('CsContratoInternoTarifario',{ref:"CsContratoInternoTarifario"})],1)]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[(
                    (_vm.$store.getters.can('cs.contratosInternos.create') ||
                      _vm.$store.getters.can('cs.contratosInternos.edit')) &&
                    _vm.estado != '2'
                  )?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form.$invalid),expression:"!$v.form.$invalid"}],staticClass:"btn bg-primary col-md-1",on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")]):_vm._e(),_c('button',{staticClass:"btn bg-secondary col-md-1",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")])])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"small-box"},[_c('div',{staticClass:"inner"},[_c('h3',{staticStyle:{"font-size":"45px"}},[_vm._v("Valores")]),_c('h3',{staticStyle:{"font-size":"14px"}},[_c('br'),_vm._v(" Valor Presupuestado: "),_c('span',{staticClass:"badge badge-pill badge-secondary"},[_vm._v(" "+_vm._s(_vm.form.valor)+" ")])]),_c('h3',{staticStyle:{"font-size":"14px"}},[_vm._v(" Valor Efectuado: "),_c('span',{staticClass:"badge badge-pill badge-success"},[_vm._v(" "+_vm._s(_vm.form.valor_efectuado)+" ")])]),_c('h3',{staticStyle:{"font-size":"14px"}},[_c('hr'),_vm._v(" Diferencia: "),_c('span',{staticClass:"badge badge-pill badge-dark"},[_vm._v(" "+_vm._s(_vm.form.valor_diferencia)+" ")])])]),_vm._m(4),_c('a',{staticClass:"small-box-footer",attrs:{"href":"#"}})])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Contratos Internos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Principal"}},[_vm._v("Principal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"link_documento"}},[_vm._v("Copia contrato "),_c('small',[_vm._v("(PDF)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header pt-2 pb-2"},[_c('h3',{staticClass:"card-title"},[_c('h6',[_c('i',{staticClass:"fas fa-users"}),_vm._v(" Datos de contacto ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"ion ion-pie-graph"})])
}]

export { render, staticRenderFns }